import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgCover from "../../assets/images/hope-probe/ground-segment/cover.png"
import imgGround from "../../assets/images/hope-probe/ground-segment/ground-segment.jpeg"
import imgMoc2 from "../../assets/images/hope-probe/ground-segment/moc-2.jpeg"
import imgMsf from "../../assets/images/hope-probe/ground-segment/msf.png"
import imgNavigation from "../../assets/images/hope-probe/ground-segment/navigation.png"
import imgAboutSectionOne from "../../assets/images/hope-probe/ground-segment/satellite.jpeg"

const GroundSegmentPage = () => (
  <Layout lang="ar">
    <SEO title="Ground Segment" lang="ar" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40">
        {/* <canvas width="100%" height="100%"></canvas> */}
        <img src={imgCover} alt="" style={{transform: "translate(50%,-50%)"}} data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{marginTop: "-100px", position: "relative"}}>
          <div className="large-container pb6">
            <div className="standard-container standard-bg">
                <div className="mb5">
                  <h2 className="relative">
                  المحطة الأرضية لمشروع الإمارات لاستكشاف المريخ
                    <div className="text-underline-heavy"></div>
                  </h2>
                  <p className="mt5">
                  تتكون المحطة الأرضية لمشروع الإمارات لاستكشاف المريخ من شبكة واسعة تضم عدة مراكز للعمليات ووحدة للتحكم في نظام الملاحة وتصميم المهام ومركز البيانات العلمية ووحدات فريق تطوير الأجهزة.
                  </p>
                  <p>
                  وتتوزع مراكز العمليات في مختبر فيزياء الغلاف الجوي والفضاء، ومركز محمد بن راشد الذي يضم أيضاً وحدة تطوير البرامج. ويقع مركز العمليات الرئيسي في مركز محمد بن راشد للفضاء، في حين يقع مركز الدعم في مختبر فيزياء الغلاف الجوي والفضاء. ويتم توجيه بيانات القياس عن بعد إلى كل من مركز محمد بن راشد للفضاء ومختبر فيزياء الغلاف الجوي والفضاء بحيث تتواجد بيانات الرحلة في كل موقع. لكن مركز التحكم الرئيسي هو المتحكم الرئيسي في المهمة بينما يكون المركز الآخر احتياطياً في حال حدوث أية طوارئ. في حين تعمل شبكة اتصالات المحطة الأرضية على دعم الاتصال بالمسبار في كل مرحلة من مراحل المهمة.
                  </p>
                  <p>
                  ويقوم فريق الملاحة بتحديد موقع المسبار وكمية الوقود اللازمة لحركته والإبقاء عليه في مساره المحدد ومداره حول المريخ. بينما ينقسم فريق تطوير الأجهزة إلى عدة فرق بحسب كل جهاز ويكون كل فريق مسؤولاً عن تطوير الجهاز واختباره، وبناء وحدات الدعم الأرضية اللزمة للجهاز.
                  </p>
                </div>
                <div className="flex flex-column flex-row-ns mb5">
                    <div className="w-100 w-40-ns">
                        <div className="mb4">
                        <div className="animated-image-container">
                            <img src={imgGround} alt=""/>
                        </div>
                        </div>
                    </div>  
                    <div className="w-100 w-60-ns pr5-ns z-index-10">
                        <div>
                            <h3>
                            شبكة اتصالات المحطة الأرضية
                            </h3>
                            <p className="mv4">
                            اختار فريق مشروع الإمارات لاستكشاف المريخ "مسبار الأمل"، شبكة ناسا لمراقبة الفضاء العميق التي تُعتبر الأفضل للتحكم في المسبار عن بعد، ويُدار هذا النظام من قبل مختبر الدفع النفاث التابع لناسا والذي يقع في باسادينا، كاليفورنيا، وهو مسؤول عن جدولة اتصالات المحطة الأرضية بالمسبار وإرسال الأوامر إليه وكذلك الحصول على البيانات التي جمعها المسبار عن بعد.
                            </p>
                        </div>
                        <div>
                            <h3>
                            تصميم المهمة
                            </h3>
                            <p className="mt4">
                            تم تصميم المهمة بواسطة شركة "الفضاء المتقدم"، وهم مسؤولون عن وضع المسار المرجعي للمسبار والحفاظ عليه، والمناورات المرجعية اللازمة لدخول المسبار إلى مداره حول وكذلك إنشاء قائمة بأحداث التجارب العلمية، والتحقق من سلامة المسبار أثناء عملية الإطلاق وخلال الرحلة.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-column flex-row-ns pv5" style={{maxWidth: "900px", marginLeft: "auto", marginRight: "auto"}}>
                     
                    <div className="w-100 w-70-ns pl5-ns z-index-10">
                        <div>
                            <h3>
                            الملاحة
                            </h3>
                            <p className="mv4">
                            تتولى شركة "كينت إكس" للملاحة الجوية، التي يقع مقرها في تمبي، أريزونا، مسؤولية تحديث بيانات الموقع الفلكي للمسبار، من خلال البيانات التي توفرها الشبكة الأرضية، كما تعمل على تحليل المسار وعمليات المناورة وتجنب الاصطدام.
                            </p>
                        </div>
                    </div>
                    <div className="w-100 w-30-ns">
                        <div className="mb4">
                        <div className="animated-image-container">
                            <img src={imgNavigation} className="w-100" alt=""/>
                        </div>
                        </div>
                    </div> 
                </div>
                <div className="flex flex-column flex-row-ns mv5">
                    <div className="w-100 w-40-ns">
                        <div className="mb4">
                        <div className="animated-image-container">
                            <img src={imgMoc2} alt=""/>
                        </div>
                        </div>
                    </div>  
                    <div className="w-100 w-60-ns pr5-ns z-index-10">
                        <div>
                            <h3>
                            مركز عمليات المهمة
                            </h3>
                            <p className="mv4">
                            يقع مركز عمليات المهمة في مركز محمد بن راشد للفضاء في دبي. وهو مسؤول عن مراقبة حالة المركبة وسلامتها، وعلميات الاستشعار عن بعد، وتطوير سلسلة الأوامر اللازمة للتحكم في المسبار وتحقيق التكامل بينها وبين أجهزة المسبار إضافة إلى دعم تخطيط المهمة وعمليات تطوير الأجهزة ومعالجة البيانات العلمية الأولية. ويُعد مركز العمليات هو المقر الرئيسي للتحكم في المسبار ومراقبته كونه يقع في مقر الجهة المالكة للمسبار والمسؤولة عن بنائه وتحميل سلسلة الأوامر عليه. وسيقوم المركز بمعالجة بيانات القياس عن بعد وأرشفتها. كما سيكون المركز مسؤولاً عن كافة إشارات البث الصادرة من شبكة اتصالات المحطة الأرضية إلى المسبار. فيما سيقوم المركز باستخلاص البيانات من المستوى صفر وإنشاء ملفات بها وإرسالها إلى مركز البيانات العلمية.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-column flex-row-ns pv5">
                     
                    <div className="w-100 w-60-ns pl5-ns z-index-10">
                        <div>
                            <h3>
                            قسم دعم المهمة
                            </h3>
                            <p className="mv4">
                            يقع مقر هذا القسم في مختبر فيزياء الغلاف الجوي والفضاء في جامعة كولورادو. ويتمتع هذا القسم بالقدرة على أداء جميع المهام التي يقوم بها مركز عمليات المهمة تخطيط العمليات العلمية الروتينية، ومعالجة البيانات من المستوى صفر. كما يمكن للقسم الاتصال مباشرة بشبكة اتصالات المحطة الأرضية ويمكنه قيادة المهمة تحت إشراف مركز العمليات الرئيسي للمهمة. وسيتولى القسم إجراء نسبة صغيرة من الاتصالات من أجل تدريب الموظفين ورفع كفاءة المرافق. ويُعد القسم هو المرجع الدائم للاختبارات والتكامل بين الأجهزة والأوامر الصادرة من مركز العمليات. ويضم القسم أيضاً الأجهزة الاحتياطية لشركة "كينت إكس"، كما أن الاتصال بين فريق الأجهزة والمسبار في الوقت الحقيقي سيتم عن طريق قسم دعم المهمة.
                            </p>
                        </div>
                    </div>
                    <div className="w-100 w-40-ns">
                        <div className="mb4">
                        <div className="animated-image-container">
                            <img src={imgMsf} alt=""/>
                        </div>
                        </div>
                    </div> 
                </div>
                <div className="flex flex-column flex-row-ns mt5">
                    <div className="w-100 w-40-ns">
                        <div className="mb4">
                        <div className="animated-image-container">
                            <img src={imgAboutSectionOne} alt=""/>
                        </div>
                        </div>
                    </div>  
                    <div className="w-100 w-60-ns pr5-ns z-index-10">
                        <div>
                            <h3>
                            مركز البيانات العلمية
                            </h3>
                            <p className="mv4">
                            يقع مركز البيانات العلمية في مركز محمد بن راشد للفضاء في دبي، وتقع على المركز مسؤولية إعداد بيانات علمية موجزة من المستوى الأول والثاني وتوزيعها على فريق مشروع الإمارات لاستكشاف المريخ والمجتمع العلمي، إضافة إلى تسهيل استكشاف واستخدام البيانات العلمية، وحفظ كافة البيانات العلمية طوال مدة المهمة، وإنشاء أرشيف لحفظ هذه البيانات بعد نهاية المهمة. وتشمل المخرجات العلمية القياسية التي سيعمل المركز على إنتاجها البيانات العلمية من المستوى الأول والثاني وهي البيانات التي سيتم نشرها لفريق مركز محمد بن راشد للفضاء والمجتمع العلمي. كما سيتلقى المركز بيانات المستوى صفر والبيانات الإضافية من مركز عمليات المهمة. وسيعمل فريق تطوير الأجهزة العلمية على تطوير برنامج لمعالجة البيانات العلمية من المستوى صفر واستخلاص البيانات العلمية الموجزة وبيانات المستويين الأول والثاني بحسب الحاجة. 
                            </p>
                        </div>
                    </div>
                </div>
                            <p>
                            كما سيعمل على حفظ البيانات العلمية التي يتم استخلاصها طوال فترة المهمة، ومن ثم يقوم بإنشاء منصات يمكن من خلالها الوصول إلى البيانات العلمية وتحليل البيانات الأساسية وتوفير كافة البيانات من خلال أدوات التجسيد المرئي، وذلك بالتعاون الوثيق مع الفريق العلمي التابع لمشروع الإمارات لاستكشاف المريخ. وبعد انتهاء المهمة سينشئ المركز أرشيفاً للبيانات العامة ومنصة يمكن من خلالها الوصول إلى بيانات مشروع الإمارات لاستكشاف المريخ واستخدامها في المهمات المستقبلية لاستكشاف الكوكب الأحمر. 
                            </p>
                <div className="mv5">
                    <h3>
                    فريق التحكم بالأجهزة
                    </h3>
                    <p className="mv4">
                    يستخدم هذا الفريق الإمكانيات والقدرات التشغيلية لبرنامج (OASIS-CC) في التحكم بالأجهزة العلمية وعملية الاستشعار عن بعد. كما أنه يعمل على معايرة البيانات الناتجة، وإدارة بيانات ومؤشرات الأجهزة العلمية، والتأكد من تنفيذ الأمر المطلوب من المسبار، كما سيطور الفريق برنامجاً لمعالجة البيانات العلمية من المستوى صفر واستخلاص البيانات العلمية الموجزة وبيانات المستويين الأول والثاني بحسب الحاجة.
                    </p>
                </div>
            </div>
          </div>  
      </section>
    </main>
  </Layout>
)

export default GroundSegmentPage
